 @media only screen and (max-width: 1020px) {
  .row { 
    margin: 0 !important;
  }
  .header { 
    width: 100%;
  }

  .header > h1 {
    width: 100%;
    text-align: center;
  }

  .header > p {
    max-width: 100% !important;
  }

  .confirmationArea { 
    flex-direction: column;
    max-width: 600px;
  }

  .confirmationArea > button {
    margin-top: 32px;
  }
}
 
.container {
  min-height: 800px;
  display: flex;
}

.container > .section {
  height: 100vh;
  width: 70%;
  margin: 0 auto;
}
 
.header {
  max-width: 800px;
  margin: 32px auto;
  padding-bottom: 32px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px dashed var(--gray-500);
}

.header > h1 {
  font-size: 54px;
  font-weight: 600;
  color: var(--gray-500);
}

.header > p {
  max-width: 368px;
  font-size: 14px;
  line-height: 1.7;
  text-align: justify;
}

.header > p > span {
  color: var(--blue-600);
  font-weight: 500;
}

.header > p > span:hover {
  cursor: pointer;
}

.form {
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 16px;
}

.form .row {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
}
 
.confirmationArea {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 32px auto;
  padding-top: 32px;
  border-top: 1px dashed var(--gray-500);
}

.confirmationArea > button { 
  height: 50px;
  width: 200px;
  background-color: var(--blue-600);
  color: var(--white);
  font-weight: 600;
  font-size: 18px;
  transition: 0.3s;
}

.confirmationArea > button:hover {
  cursor: pointer;
  filter: brightness(0.8);
}

.confirmationArea > .checkbox {
  display: flex;
  align-items: center;
  height: 30px;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 14px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.confirmationArea > .checkbox > input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.confirmationArea > .checkbox > p { 
  margin: auto 0;
}

.confirmationArea > .checkbox > .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: var(--gray-300);
}

.checkbox:hover input ~ .checkmark {
  background-color: var(--gray-400);
}

.checkbox input:checked ~ .checkmark {
  background-color: var(--blue-600);
}

.checkbox > .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.checkbox input:checked ~ .checkmark:after {
  display: block;
}

.checkbox .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid var(--white);
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.aside { 
  height: 100vh;
  min-height: 1200px;
  width: 30%;

  margin: auto 0;
  padding: 32px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--blue-300);
}

.aside > img { 
  color: var(--white);
  width: 200px;
}

.aside > .icons {
  max-width: 400px;
  max-height: 600px;
  height: 100%;
  margin-top: 64px;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-evenly;
}

.aside > .icons > img {
  width: 150px;
}

