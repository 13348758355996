:root {
  --white: #FFF;

  --gray-300: #EEE;
  --gray-400: #DDD;
  --gray-500: #666;
  --gray-700: #333;

  --blue-300: #1EBCEF;
  --blue-600: #0368FF;
}

@media only screen and (max-width: 600px) {
  body { 
    margin: 0 auto !important;
    display: flex;
    justify-content: center;
  }

  section { 
    width: 90% !important;
  } 

  section > header > h1 {
    text-align: start !important;
  }
} 

@media only screen and (max-width: 1020px) {
  body { 
    width: 100%;
  }

  section > header {
    flex-direction: column;
    min-width: 350px;
  }

  section > header > p { 
    margin-top: 16px;
  }

  h1 { 
    font-size: 38px !important;
  }

  aside { 
    display: none !important;
  }

  section { 
    max-width: 600px !important;
  }
  
  p, span {
    font-size: 12px;
  }

  form {
    min-width: 350px !important;
  }

  form > div { 
    flex-direction: column;
  }

  img { 
    width: 100px !important;
  }
   
  input {
    width: 300px;
  }
}

@media only screen and (max-width: 1200px) {
  aside {
    display: none !important;
  }
}

* {
  margin: 0;
  padding: 0;
  border: none;
  box-sizing: border-box;
  font-family: 'Fira Sans', sans-serif;
}

body {
  height: 100vh;
}

input:focus {
  border: 0 none;
  outline: none;
  box-shadow: 0 0 0 0;
}

select:focus {
  border: 0 none;
  outline: none;
  box-shadow: 0 0 0 0;
}

