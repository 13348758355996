.container {
   min-width: 350px;
}

@media only screen and (max-width: 1000px) {
   .container {
      margin: 8px 0;
   }
}

/* Checkbox estilização */

.checkGroup {
  min-width: 350px;
  height: 40px;
  display: flex;
  justify-content: space-between;
}

.checkGroup > .label {
  display: flex; 
}

.checkGroup > .label:hover {
  cursor: pointer;
}

.checkGroup > .label > input {
  display: none;
}

.checkGroup > .label > span {
  border: 1px solid var(--gray-500); 
  width: 80px; 
  height: 40px; 
  overflow: hidden; 
  line-height: 3; 
  text-align: center; 
  font-size: 14px;
  color: var(--gray-500);
  font-weight: 500;
  left: 0; 
  top: 50%; 
}

.checkGroup input:checked + span {
  background: var(--blue-600); 
  color: var(--white);
  border-color: var(--blue-600);
}

/* Label */
.title {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 16px;
   color: var(--gray-500);
}

.help {
   width: 12px;
   height: 12px;
   
   text-align: center;
   font-size: 12px;

   border-radius: 12px;
   border: 1px solid var(--gray-300);
   
   background-color: var(--gray-300);
   color: var(--white);

   position: relative;
   display: inline-block;
}

.help .helpMessage {
   visibility: hidden;
   font-size: 14px;
   
   display: flex;
   align-items: center;
   justify-content: center;
   
   width: 120px;
   
   background-color: rgba(6, 6, 6, 0.8);
   color: var(--white);
   
   border-radius: 6px;
   padding: 5px 0;
   position: absolute;
   
   z-index: 1;
   bottom: 150%;
   left: 50%;
   margin-left: -60px;
}

.help .helpMessage::after {
   content: "";
   position: absolute;
   top: 100%;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: rgba(6, 6, 6, 0.8) transparent transparent transparent;
}

.help:hover { 
   cursor: pointer;
}

.help:hover .helpMessage { 
   visibility: visible;
}
