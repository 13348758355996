@media only screen and (max-width: 1020px) {
   .container { 
      margin: 8px 0;
   }

   .containerFiliais { 
      margin: 8px 0;
   }
}

.container {
   display: flex; 
   flex-direction: column;
   min-width: 350px;
}

.containerFiliais {
   display: none; 
   flex-direction: column;
   min-width: 350px;
}

.label {
   display: flex;
   justify-content: space-between;
   align-items: center;
   margin-bottom: 16px;
   color: var(--gray-500);
}

.help {
   width: 12px;
   height: 12px;
   
   text-align: center;
   font-size: 12px;

   border-radius: 12px;
   border: 1px solid var(--gray-300);
   
   background-color: var(--gray-300);
   color: var(--white);

   position: relative;
   display: inline-block;
}

.help .helpMessage {
   visibility: hidden;
   font-size: 14px;
   
   display: flex;
   align-items: center;
   justify-content: center;
   
   width: 120px;
   
   background-color: rgba(6, 6, 6, 0.8);
   color: var(--white);
   
   border-radius: 6px;
   padding: 5px 0;
   position: absolute;
   
   z-index: 1;
   bottom: 150%;
   left: 50%;
   margin-left: -60px;
}

.help .helpMessage::after {
   content: "";
   position: absolute;
   top: 100%;
   left: 50%;
   margin-left: -5px;
   border-width: 5px;
   border-style: solid;
   border-color: rgba(6, 6, 6, 0.8) transparent transparent transparent;
}

.help:hover { 
   cursor: pointer;
}

.help:hover .helpMessage { 
   visibility: visible;
}

.row {
  margin: 16px 0;
  display: flex;
  justify-content: space-between;
}      

.input {
   height: 40px;
   width: 100%;
   padding: 0 8px;
   text-transform: uppercase;
   border: 1px solid var(--gray-500);
}

.input:hover {
   border: 1px solid var(--blue-600);
}             

.input:focus {
   border: 2px solid var(--blue-600);
} 
