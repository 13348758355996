.container {
   position: fixed;
   width: 100%;
   height: 100%;
   top: 0;
   left: 0;
   background: #f8f8f8ad;
}

.spinner { 
   top: 40%;
   left: 38%;
   z-index: 1000;
   position: absolute;
}
